<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <h1>Covid-19 Updates</h1>
        <div style="height: 650px" v-if="loading">
          <div class="embed-responisve embed-responsive-16by9">
            <iframe
              class="embed-responsive-item"
              title="Covid-19"
              width="1024"
              height="612"
              src="https://app.powerbi.com/view?r=eyJrIjoiYTViZDA5MmUtMmQ1Yi00MDMwLTk5YzctYzc1M2ZhNzVmMGU1IiwidCI6IjE5YjYyNGNlLTUyNGItNDE1MS05YjU4LWE4ZjBkYTU3NGFlYSIsImMiOjEwfQ%3D%3D&pageName=ReportSection120db765aeba0d8c3109"
              frameborder="1"
              allowFullScreen="true"
            ></iframe>
          </div>
        </div>
      </div>
      <div class="col-xl-6">
        <p>Singapore Covid situation</p>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12, col-xl-6"></div>
      <div class="col-lg-12, col-xl-6"></div>
    </div>
    <div class="col-lg-12, col-xl-12"></div>
    <div class="col-lg-12, col-xl-12"></div>
  </div>

  <p></p>
  <p></p>
</template>

<script>
/* import { logEvent } from "firebase/analytics";
import { analytics } from "@/firebase/config";
const log = logEvent(analytics, "page_view", {
  page_title: "Covid-19",
});
log; */

export default {
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    iframeLoad() {
      this.loading = true;
      console.log("Loading iframe");
    },
  },
  mounted() {
    console.log("mounted");
    this.iframeLoad();
  },
  name: "Covid-19",
  title: "Covid-19",
};
</script>

<style></style>
